import React from "react";

// React
import { Disclosure } from "@headlessui/react";

// Shared Components
import Footer from "../../components/shared/footer/Footer";
import Header from "../../components/shared/header/Header";
import Seo from "../../components/seo";

// Icons
import { ChevronDownIcon } from "@heroicons/react/outline";
import { BadgeCheckIcon, CheckIcon } from "@heroicons/react/solid";

// Images
import HeaderImg from "../../images/features/granular-permissions/header.png";
import Feature from "../../images/features/granular-permissions/feature-showcase.png";
import { Link } from "gatsby";

const faqs = [
  {
    question:
      "How do I know if my central station is compatible with Tech Tester?",
    answer: `Please take a look at our list of currently compatible central stations at alarmtechtester.com/compatible-central-stations.`,
  },
  {
    question: "What do I need to setup an account with Tech Tester?",
    answer:
      "All you need to get started with Tech Tester is a valid Central Station log-in with the appropriate permissions allowed. After you link your credentials to Tech Tester, you are all ready to get started!",
  },
  {
    question: "I use multiple central stations. Does Tech Tester allow that?",
    answer:
      "Absolutely. Tech Tester will allow you to link as many central stations to your account as you need.",
  },
  {
    question:
      "My free trial has expired, how do I continue to use Tech Tester?",
    answer:
      "To continue using Tech Tester, please enter your payment details within your account screen. If you need help with payments, please feel free to reach out to our support team, who will gladly assist in getting you back up and running with Tech Tester.",
  },
  {
    question: "When will my credit card be billed for Tech Tester?",
    answer:
      "Your credit card will be billed on the 1st of each month. If you joined late in the month, your credit card charge will reflect that. You will pay a pro-rated amount, only for the time you were using Tech Tester.",
  },
  {
    question:
      "I would like one of my technicians to have full access. Is that possible?",
    answer:
      "Absolutely. With Tech Tester, you are in full control over how much (or how little) access your technicians have. You can change the access levels at any time.",
  },
];

const steps = [
  { name: "Time Based App Access", status: "complete" },
  {
    name: "account access limited by date",
    status: "complete",
  },
  {
    name: "permission based access to account information",
    status: "complete",
  },
  { name: "detailed audit logs", status: "complete" },
  {
    name: "automated usage reports straight to your inbox",
    status: "complete",
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function GranularPermissions() {
  return (
    <div>
      <Seo
        title="Granular Permissions"
        description="Learn about granular team management with Tech Tester."
      />
      <Header />
      {/* Hero section */}
      <div className="bg-myGray-300">
        <div
          className={
            "pt-24 px-10 mx-auto max-w-6xl grid grid-cols-1 md:grid-cols-2 gap-10"
          }
        >
          <div className={"my-auto text-left "}>
            <h1 className={"md:text-4xl text-3xl text-myGray-600 font-bold"}>
              Granular Permissions
            </h1>
            <h3 className={"text-myGray-600 text-lg pt-10"}>
              Tech Tester allows you to give your technicians as much or as
              little information about your account base that you want.
            </h3>
            <Link to={"/contact"}>
              <button className="mt-10 inline-flex px-4 py-2 text-base border border-transparent rounded-md shadow-sm text-sm text-black font-bold bg-yellowTT hover:bg-yellowTT-alt">
                START NOW
              </button>
            </Link>
          </div>
          <div className={"m-auto"}>
            <img className="" src={HeaderImg} alt="Temp Item" />
          </div>
        </div>
      </div>

      {/* Alternating Feature Sections */}
      <div className="relative overflow-hidden">
        <div className={"lg:mx-32"}>
          <div
            aria-hidden="true"
            className="absolute z-10 inset-x-0 top-0 h-48 bg-gradient-to-b from-gray-100"
          />

          <div
            className={
              "grid grid-cols-1 md:grid-cols-2 p-5 lg:py-20 mt-24 justify-center px-6 md:px-24"
            }
          >
            <div className={"flex flex-row items-start items-center"}>
              <BadgeCheckIcon className={"h-10 w-10 text-navy"} />
              <p className="text-lg font-medium text-black p-5">
                Want to give your technicians full access to search your account
                base?
                <span className={"pl-2 font-extrabold text-navy"}>Sure!</span>
              </p>
            </div>
            <div className={"flex flex-row items-start items-center"}>
              <BadgeCheckIcon className={"h-10 w-10 text-navy"} />
              <p className="text-lg font-medium text-black p-5">
                {" "}
                Want to only assign them the account they are working on that
                day?
                <span className={"pl-2 font-extrabold text-navy"}>
                  Sure!
                </span>{" "}
              </p>
            </div>
            <div className={"flex flex-row items-start items-center"}>
              <BadgeCheckIcon className={"h-10 w-10 text-navy"} />
              <p className="text-lg font-medium text-black p-5">
                Want them to be able to view and edit all site information?
                Zones? Contacts?
                <span className={"pl-2 font-extrabold text-navy"}>Sure!</span>
              </p>
            </div>
            <div className={"flex flex-row items-start items-center"}>
              <BadgeCheckIcon className={"h-10 w-10 text-navy"} />
              <p className="text-lg font-medium text-black p-5">
                Want them to only see limited site data, testing and signal
                information?
                <span className={"pl-2 font-extrabold text-navy"}>
                  No Problem!
                </span>
              </p>
            </div>
          </div>
        </div>

        <h1 className={"font-bold text-3xl mb-10 text-center"}>
          With Tech Tester, you are in control!
        </h1>

        <div className="relative mt-24 py-24 bg-myGray-200">
          <div className="relative z-10 grid grid-cols-1 text-center place-items-center">
            <div className="mt-6 p-10">
              <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
                Transparent Central Station Access
              </h2>
              <p className="mt-4 text-lg text-gray-500 max-w-5xl">
                Tech Tester allows you to assign central station credentials for
                your technicians. What does this mean? They don’t have their own
                credentials so they can’t use any another product to access your
                accounts.
                <br /> Central station access is managed by the logins that you
                set, and is never available to the technician. <br />
                Your account data stays with you and only you!{" "}
              </p>
              <div className="mt-6">
                <Link
                  to="/resources/how-it-works"
                  className="inline-flex bg-gradient-to-r from-silver-light to-silver-light bg-origin-border px-4 py-2 border border-transparent text-base font-semibold rounded-md shadow-md text-black hover:from-silver-alt hover:to-silver-alt"
                >
                  Learn More
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="relative">
          <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
            {/* Permissions */}
            <div className={"pt-24 ml-8"}>
              <nav aria-label="Progress">
                <ol className="overflow-hidden">
                  {steps.map((step, stepIdx) => (
                    <li
                      key={step.name}
                      className={classNames(
                        stepIdx !== steps.length - 1 ? "pb-10" : "",
                        "relative"
                      )}
                    >
                      {step.status === "complete" ? (
                        <>
                          {stepIdx !== steps.length - 1 ? (
                            <div
                              className="-ml-px absolute mt-0.5 top-4 left-4 w-0.5 h-full bg-navy"
                              aria-hidden="true"
                            />
                          ) : null}
                          <div className="relative flex items-start group">
                            <span className="h-9 flex items-center">
                              <span className="relative z-10 w-8 h-8 flex items-center justify-center bg-navy rounded-full group-hover:bg-navy-light">
                                <CheckIcon
                                  className="w-5 h-5 text-white"
                                  aria-hidden="true"
                                />
                              </span>
                            </span>
                            <span className="ml-4 min-w-0 flex flex-col mt-2">
                              <span className="text-xs font-semibold tracking-wide uppercase">
                                {step.name}
                              </span>
                            </span>
                          </div>
                        </>
                      ) : (
                        <>
                          {stepIdx !== steps.length - 1 ? (
                            <div
                              className="-ml-px absolute mt-0.5 top-4 left-4 w-0.5 h-full bg-gray-300"
                              aria-hidden="true"
                            />
                          ) : null}
                          <div className="relative flex items-start group">
                            <span
                              className="h-9 flex items-center"
                              aria-hidden="true"
                            >
                              <span className="relative z-10 w-8 h-8 flex items-center justify-center bg-white border-2 border-gray-300 rounded-full group-hover:border-gray-400">
                                <span className="h-2.5 w-2.5 bg-transparent rounded-full group-hover:bg-gray-300" />
                              </span>
                            </span>
                            <span className="ml-4 min-w-0 flex flex-col">
                              <span className="text-xs font-semibold tracking-wide uppercase text-gray-500">
                                {step.name}
                              </span>
                            </span>
                          </div>
                        </>
                      )}
                    </li>
                  ))}
                </ol>
              </nav>
            </div>

            <div className="m-auto mr-4 pt-16">
              <div className="pl-4 sm:pl-6  ml-2 md:-ml-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
                <img className="" src={Feature} alt="Temp Item" />
              </div>
            </div>
          </div>
        </div>

        {/* FAQ Section */}

        <div className="bg-white mt-12">
          <div className="max-w-7xl mx-auto py-12 px-4 sm:py-16 sm:px-6 lg:px-8">
            <div className="max-w-3xl mx-auto">
              <h2 className="text-center text-3xl font-extrabold text-gray-900">
                Frequently asked questions
              </h2>
              {faqs.map((faq) => (
                <Disclosure
                  as="div"
                  key={faq.question}
                  className="pt-6 bg-myGray-300 rounded-md m-5 p-5"
                >
                  {({ open }) => (
                    <>
                      <dt className="text-lg">
                        <Disclosure.Button className="text-left w-full flex justify-between items-start text-gray-400">
                          <span className="font-medium text-gray-900">
                            {faq.question}
                          </span>
                          <span className="ml-6 h-7 flex items-center">
                            <ChevronDownIcon
                              className={classNames(
                                open ? "-rotate-180" : "rotate-0",
                                "h-6 w-6 transform"
                              )}
                              aria-hidden="true"
                            />
                          </span>
                        </Disclosure.Button>
                      </dt>
                      <Disclosure.Panel as="dd" className="mt-2 pr-12">
                        <p className="text-base text-gray-500">{faq.answer}</p>
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
              ))}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
